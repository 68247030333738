<script>
  import CTwoRow from '@/components/grid/cells/shared/CTwoRow';

  export default {
    extends: CTwoRow,
    computed: {
      deadLine() { return this.propData?.externalDeadline; },
      timeTillDeadline() {
        const now = this.$moment();
        const deadLine = this.$moment(this.deadLine);
        const duration = this.$moment.duration(deadLine.diff(now));
        const days = ((duration.days() % 7) && (duration.days() % 7) > 0) ? (duration.days() % 7) + 'D ' : '0D ';
        const weeks = (duration.weeks() && duration.weeks() > 0) ? duration.weeks() + 'W ' : '0W ';
        const months = (duration.months() && duration.months() > 0) ? duration.months() + 'M ' : '0M ';

        return months + weeks + days;
      },
      isDeadlinePast() { return this.$moment().isAfter(this.deadLine); },
      firstRow() { return this.deadLine ? this.$moment(this.deadLine).format('DD.MM.YYYY, HH:mm') : '-'; },
      secondRow() { return this.isDeadlinePast ? this.$gettext('Deadline past') : this.timeTillDeadline; }
    },
  };
</script>
