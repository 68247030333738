<script>
  import CPersonInfo from '@/components/grid/cells/shared/CPersonInfo';

  export default {
    extends: CPersonInfo,
    computed: {
      qualificationsList() { return this.$store.state.selectsData.qualification || []; },
      owner() { return this.propData?.translator; },
      ownerEmail() { return this.owner?.email; },
      ownerPhone() { return this.owner?.phone; },
      qualificationId() { return this.owner?.qualificationId; },
      qualification() { return this.qualificationsList?.find((quali) => quali.id === this.qualificationId)?.shortName; },
      secondRow() { return this.qualification || '-'; },
      firstRow() { return this.owner?.name; }
    },
  };
</script>
