<script>
  import CNotes from '@/components/grid/cells/job/CNotes';

  export default {
    extends: CNotes,
    computed: {
      notes() { return this.params.data?.note; },
      firstRow() { return this.notes?.note || ''; },
      secondRow() { return ''; },
      noteStyle() {
        return {display: '-webkit-box !important',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
                'white-space': 'normal'};
      }
    }
  };
</script>
