(<template>
  <div class="projects">
    <div class="projects__header">
      <div class="projects__header--left">
        <h2 class="projects__header-title">{{ $gettext('Translation assignments') }}</h2>
        <button class="sk-btn projects__header-refresh-button"
                @click="updateGridData">{{ $gettext('Update') }}</button>
      </div>
    </div>
    <div class="projects__cont">

      <!------- All Tabs ------->
      <div class="project-tabs__cont">
        <div class="project-tabs__wrapper">
          <multiselect-tab v-for="tab in leftSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.managersList"
                           :items-list="computedManagersList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :stat-needs-update="tab.statNeedsUpdate"
                           :tab="tab"
                           @checkboxeschanged="setTabForUpdate(tab.tab, $event)" />
        </div>
        <div class="project-tabs__wrapper">
          <multiselect-tab v-for="tab in rightSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.managersList"
                           :items-list="computedManagersList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :tab="tab" />
        </div>
      </div>
      <!------- Grid Component ------->
      <ag-grid :column-defs="columnDefs"
               :grid-options="gridOptions"
               :fetch-data-func="fetchDataFunc"
               :grid-data="gridData"
               @interface="getChildInterface" />
    </div>
  </div>
</template>)

<script>
/* eslint-disable vue/no-unused-components */
// For Grid filter components
  import {mapActions, mapMutations, mapState} from 'vuex';
  import LanguagePairFilter from '@/components/grid/filters/shared/LanguagePairFilter';
  import SessionTypeFilter from '@/components/grid/filters/shared/SessionTypeFilter';
  import ProjectStatusFilter from '@/components/grid/filters/ProjectStatusFilter';
  import SubtaskStatusFilter from '@/components/grid/filters/SubtaskStatusFilter';
  import AssigneesFilter from '@/components/grid/filters/AssigneesFilter';
  import OwnerParticipantFilter from '@/components/grid/filters/OwnerParticipantFilter';
  import PublicIdFilter from '@/components/grid/filters/PublicIdFilter';
  import OrderHintsFilter from '@/components/grid/filters/hint/OrderHintsFilter';
  import SubtaskHintsFilter from '@/components/grid/filters/hint/SubtaskHintsFilter';
  import PageWithGridMixin from '@/mixins/PageWithGridMixin';
  // ---- Cells --- //
  import CProjectStatusChip from '@/components/grid/cells/project/CProjectStatusChip';
  import CSubtaskStatusChip from '@/components/grid/cells/project/CSubtaskStatusChip';
  import CLanguagePair from '@/components/grid/cells/project/CLanguagePair';
  import CId from '@/components/grid/cells/project/CId';
  import COwner from '@/components/grid/cells/project/COwner';
  import CTranslator from '@/components/grid/cells/project/CTranslator';
  import CViewers from '@/components/grid/cells/project/CViewers';
  import COrderHints from '@/components/grid/cells/project/COrderHints';
  import CSubtaskHints from '@/components/grid/cells/project/CSubtaskHints';
  import CNotes from '@/components/grid/cells/project/CNotes';
  import CWordCount from '@/components/grid/cells/project/CWordCount';
  import CExternalDeadline from '@/components/grid/cells/project/CExternalDeadline';
  import CInternalDeadline from '@/components/grid/cells/project/CInternalDeadline';
  import WSManager from '@/modules/ws_data_module';

  export default {
    asyncData({store}) {
      let promisesArr = [];

      promisesArr = [...promisesArr, store.dispatch('ManagerSocketDataStore/getManagerSubtaskStats')];
      promisesArr = [...promisesArr, store.dispatch('ManagerSocketDataStore/getManagerSubtaskTabsAssignments')];

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'language-pair-filter': LanguagePairFilter,
      'assignees-filter': AssigneesFilter,
      'text-filter': OwnerParticipantFilter,
      'session-type-filter': SessionTypeFilter,
      'project-status-filter': ProjectStatusFilter,
      'subtask-status-filter': SubtaskStatusFilter,
      'public-id-filter': PublicIdFilter,
      'order-hints-filter': OrderHintsFilter,
      'subtask-hints-filter': SubtaskHintsFilter,
      'c-project-status-chip': CProjectStatusChip,
      'c-subtask-status-chip': CSubtaskStatusChip,
      'c-language-pair': CLanguagePair,
      'c-id': CId,
      'c-translator': CTranslator,
      'c-owner': COwner,
      'c-viewers': CViewers,
      'c-order-hints': COrderHints,
      'c-subtask-hints': CSubtaskHints,
      'c-notes': CNotes,
      'c-word-count': CWordCount,
      'c-external-deadline': CExternalDeadline,
      'c-internal-deadline': CInternalDeadline,
      'ag-grid': () => import('@/components/grid/GridComponent')
    },
    mixins: [PageWithGridMixin],
    data() {
      return {
        currentView: this.$route.query.view || 'all',
        fetchGridDataAction: 'AllProjectsStore/getManagerSubtasks'
      };
    },
    computed: {
      ...mapState('AllProjectsStore', {
        updateManagerSubtasksGrid: (state) => state.updateManagerSubtasksGrid || '',
        filterOrderId: (state) => state.filterOrderId || '',
      }),
      ...mapState('ManagerSocketDataStore', {
        // -- Assignable Tabs Job Count -- //
        thisWeekCount: (state) => state.translationTabs.thisWeek.count || 0,
        nextWeekCount: (state) => state.translationTabs.nextWeek.count || 0,
        thisMonthCount: (state) => state.translationTabs.thisMonth.count || 0,
        // -- Non-assignable Tabs Job Count -- //
        all: (state) => state.translationTabs.all.count || 0,
        // -- Assigned managers to Tabs -- //
        thisWeekManagers: (state) => state.translationTabs.thisWeek.managers || [],
        nextWeekManagers: (state) => state.translationTabs.nextWeek.managers || [],
        thisMonthManagers: (state) => state.translationTabs.thisMonth.managers || [],
        // -- Copy Subtask Ids -- //
        thisWeekCopySubtaskIds: (state) => state.translationTabsCopy.thisWeek?.subtaskIds || [],
        nextWeekCopySubtaskIds: (state) => state.translationTabsCopy.nextWeek?.subtaskIds || [],
        thisMonthCopySubtaskIds: (state) => state.translationTabsCopy.thisMonth?.subtaskIds || [],
        // -- Subtask Ids -- //
        thisWeekSubtaskIds: (state) => state.translationTabs.thisWeek?.subtaskIds || [],
        nextWeekSubtaskIds: (state) => state.translationTabs.nextWeek?.subtaskIds || [],
        thisMonthSubtaskIds: (state) => state.translationTabs.thisMonth?.subtaskIds || [],
        translationTabs: (state) => state.translationTabs || {},
      }),
      columnDefs() {
        return [
          {
            headerName: this.$gettext('Subtask ID'),
            field: 'publicId',
            maxWidth: 180,
            flex: 1,
            floatingFilterComponent: 'public-id-filter',
            ...this.textFilterNoMenu,
            cellRendererSelector: (params) => {
              return {
                component: 'c-id',
                params: params
              };
            },
            condition: true
          },
          {headerName: this.$gettext('Project Status'),
           field: 'orderStatus',
           minWidth: 160,
           flex: 1,
           floatingFilterComponent: 'project-status-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-project-status-chip',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Owner & access'),
           field: 'owner',
           flex: 1,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'text-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-owner',
               params: params
             };
           },
           condition: true},
          {
            headerName: this.$gettext('Hints'),
            field: 'projectHints',
            minWidth: 120,
            width: 120,
            maxWidth: 160,
            floatingFilterComponent: 'order-hints-filter',
            ...this.multiSelectFilterParams,
            cellRendererSelector: (params) => {
              const combinedParams = {
                ...params,
                hasAwardedInterpreter: false
              };
              return {
                component: 'c-order-hints',
                params: combinedParams
              };
            },
            condition: true
          },
          {headerName: this.$gettext('Created at'),
           maxWidth: 140,
           flex: 1,
           cellClass: 'center-grid-column-data',
           sortable: true,
           field: 'orderCreatedAt',
           suppressMenu: true,
           filter: 'agDateColumnFilter',
           valueFormatter: this.dateFormatter,
           filterParams: this.dateFilterParams,
           condition: true},
          {headerName: this.$gettext('External Deadline'),
           flex: 1,
           maxWidth: 140,
           suppressMenu: true,
           filter: 'agDateColumnFilter',
           filterParams: this.dateFilterParams,
           field: 'externalDeadline',
           cellRendererSelector: (params) => {
             return {
               component: 'c-external-deadline',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Subtask Status'),
           field: 'subtaskStatus',
           flex: 1,
           floatingFilterComponent: 'subtask-status-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-subtask-status-chip',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Language Pair'),
           field: 'langId',
           flex: 1,
           floatingFilterComponent: 'language-pair-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             const allParam = {
               ...params,
               data: {
                 ...params.data,
                 noActionOnClick: true
               }
             };
             return {
               component: 'c-language-pair',
               params: allParam
             };
           },
           condition: true},
          {headerName: this.$gettext('Translator'),
           field: 'translator',
           flex: 1,
           floatingFilterComponent: 'text-filter',
           ...this.textFilterNoMenu,
           cellRendererSelector: (params) => {
             return {
               component: 'c-translator',
               params: params
             };
           },
           condition: true},
          {
            headerName: this.$gettext('Hints'),
            field: 'subtaskHints',
            minWidth: 120,
            width: 120,
            maxWidth: 160,
            floatingFilterComponent: 'subtask-hints-filter',
            ...this.multiSelectFilterParams,
            cellRendererSelector: (params) => {
              const combinedParams = {
                ...params,
                hasAwardedInterpreter: false
              };
              return {
                component: 'c-subtask-hints',
                params: combinedParams
              };
            },
            condition: true
          },
          {
            headerName: this.$gettext('Assignee'),
            field: 'assignedManagerUid',
            cellClass: 'hasPopup',
            maxWidth: 80,
            floatingFilterComponent: 'assignees-filter',
            ...this.multiSelectFilterParams,
            cellRendererSelector: (params) => {
              return {
                component: 'c-viewers',
                params: params
              };
            },
            condition: true
          },
          {headerName: this.$gettext('Wordcount'),
           field: 'wordcount',
           maxWidth: 100,
           filter: 'agNumberColumnFilter',
           filterParams: this.numberFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-word-count',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Internal Deadline'),
           minWidth: 140,
           maxWidth: 140,
           suppressMenu: true,
           filter: 'agDateColumnFilter',
           filterParams: this.dateFilterParams,
           field: 'internalDeadline',
           cellRendererSelector: (params) => {
             return {
               component: 'c-internal-deadline',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Notes'),
           field: 'note',
           minWidth: 250,
           cellRendererSelector: (params) => {
             return {
               component: 'c-notes',
               params: params
             };
           },
           condition: true},
        ].filter((column) => column.condition);
      },
      managersList() { return this.$store.state.selectsData.managers; },
      computedManagersList() {
        if (this.managersList.length) {
          return this.managersList.map((manager) => {
            return {
              id: manager.uid,
              name: manager.name ? manager.name : manager.email,
              avatarPath: manager.avatarPath,
            };
          });
        }
        return [];
      },
      gridData() {
        return {
          withDefaultSort: false,
          page: this.currentPage,
          items: this.items,
          ransackParams: this.tabRansackParams
        };
      },
      tabRansackParams() {
        switch (this.currentView) {
          case 'this_week':
            return {
              's[this_week]': true
            };
          case 'next_week':
            return {
              's[next_week]': true
            };
          case 'this_month':
            return {
              's[this_month]': true
            };
          case 'all':
            return {};
        }
      },
      isCurrentWeek() { return this.currentView === 'this_week'; },
      isNextWeek() { return this.currentView === 'next_week'; },
      isThisMonth() { return this.currentView === 'this_month'; },
      isAllView() { return this.currentView === 'all'; },
      leftSideTabs() {
        return [
          {
            tab: 'this_week',
            isActive: this.isCurrentWeek,
            title: this.$gettext('This week'),
            managersList: this.thisWeekManagers,
            count: this.thisWeekCount,
            withAvatar: true,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            catchTabCheckbox: this.catchTabCheckbox,
            statNeedsUpdate: this.isThereNewThisWeekSubtask
          },
          {
            tab: 'next_week',
            isActive: this.isNextWeek,
            title: this.$gettext('Next week'),
            managersList: this.nextWeekManagers,
            count: this.nextWeekCount,
            withAvatar: true,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            catchTabCheckbox: this.catchTabCheckbox,
            statNeedsUpdate: this.isThereNewNextWeekSubtask
          },
          {
            tab: 'this_month',
            isActive: this.isThisMonth,
            title: this.$gettext('This month'),
            managersList: this.thisMonthManagers,
            count: this.thisMonthCount,
            withAvatar: true,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            catchTabCheckbox: this.catchTabCheckbox,
            statNeedsUpdate: this.isThereNewThisMonthSubtask
          }
        ];
      },
      rightSideTabs() {
        return [
          {
            tab: 'all',
            isActive: this.isAllView,
            title: this.$gettext('All'),
            count: this.all,
            onClick: this.setCurrentView
          }
        ];
      },
      isThereNewThisWeekSubtask() { return !this.thisWeekSubtaskIds.every((id) => this.thisWeekCopySubtaskIds.includes(id)); },
      isThereNewNextWeekSubtask() { return !this.nextWeekSubtaskIds.every((id) => this.nextWeekCopySubtaskIds.includes(id)); },
      isThereNewThisMonthSubtask() { return !this.thisMonthSubtaskIds.every((id) => this.thisMonthCopySubtaskIds.includes(id)); }
    },
    watch: {
      updateManagerSubtasksGrid() {
        this.updateGridData();
      },
      filterOrderId(newValue) {
        const newParam = this.tabRansackParams;
        newParam['s[order_id_eq]'] = newValue || '';
        this.$options.childInterface.updateData({ransackParams: this.tabRansackParams, resetFilters: true});
      }
    },
    methods: {
      ...mapMutations('ManagerSocketDataStore', [
        'setCopyThisWeekStats',
        'setCopyNextWeekStats',
        'setThisMonthStats'
      ]),
      ...mapActions('ManagerSocketDataStore', ['updateManagerSubtaskTabsAssignment']),
      setTabForUpdate(tabId, event) {
        this.tabForUpdate = tabId;
        this.catchTabCheckbox(event);
      },
      catchTabCheckbox(value) {
        const assignments = {};
        const joinedValue = value.join(',');
        switch (this.tabForUpdate) {
          case 'this_week':
            assignments.thisWeek = joinedValue;
            break;
          case 'next_week':
            assignments.nextWeek = joinedValue;
            break;
          case 'this_month':
            assignments.thisMonth = joinedValue;
            break;
        }
        this.updateManagerSubtaskTabsAssignment({assignments});
      },
      setCurrentView(view, ransackParams) {
        this.currentView = view;
        this.ransackParams = ransackParams;
        this.updateGridData();
        this.updateTabStat();
      },
      updateTabStat() {
        switch (this.currentView) {
          case 'this_week':
            this.setCopyThisWeekStats({
              ids: this.thisWeekSubtaskIds,
              count: this.translationTabs.thisWeek.count,
            });
            break;
          case 'next_week':
            this.setCopyNextWeekStats({
              ids: this.nextWeekSubtaskIds,
              count: this.translationTabs.nextWeek.count,
            });
            break;
          case 'this_month':
            this.setThisMonthStats({
              ids: this.thisMonthSubtaskIds,
              count: this.translationTabs.thisMonth.count,
            });
            break;
        }
      },
      subscribeToManagerTranslationChannels(route, vm) {
        if (vm.$webSocket) {
          vm.$root.$subtasksStatisticsChannel = vm.$webSocket.subscribe((data) => {
            WSManager.setDataFromSubtasksStatisticsChannel(vm, data);
          }, 'SubtasksStatisticsChannel');
          vm.$root.$subtasksScopeManagerAssignmentChannel = vm.$webSocket.subscribe((data) => {
            WSManager.setDataFromSubtasksScopesManagersAssignmentsChannel(vm, data);
          }, 'SubtasksScopesManagersAssignmentsChannel');
        }
      },
      unsubscribeFromManagerTranslationChannels(route, vm) {
        if (vm.$root.$subtasksStatisticsChannel && vm.$webSocket) {
          vm.$root.$subtasksStatisticsChannel.unsubscribe();
        }
        if (vm.$root.$subtasksScopeManagerAssignmentChannel && vm.$webSocket) {
          vm.$root.$subtasksScopeManagerAssignmentChannel.unsubscribe();
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.subscribeToManagerTranslationChannels(to, vm);
      });
      if (to.query.view == {} || to.query.view == null || to.query.view == undefined) {
        next((vm) => {
          return {path: to.path, query: {view: 'all', page: 1, items: vm.$store.state.filterItemsAmount}};
        });
      }
      next();
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('ManagerSocketDataStore/clearStore');
      this.unsubscribeFromManagerTranslationChannels(from, this);
      next();
    },
    childInterface: {
      updateData: () => {}
    },
  };
</script>
<style>
.projects__cont .ag-cell-value:nth-child(5) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.projects__cont .ag-cell-value:nth-child(1) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.projects__cont .ag-cell-value:nth-child(1),
.projects__cont .ag-cell-value:nth-child(2),
.projects__cont .ag-cell-value:nth-child(3),
.projects__cont .ag-cell-value:nth-child(4),
.projects__cont .ag-cell-value:nth-child(5) {
  background-color: #f4f5f7;
}
</style>
<style scoped>
.projects {
  margin: 10px 20px;
}

.projects__cont {
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);
}

.project-tabs__cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d3d5de;
}

.project-tabs__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.projects__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.projects__header--left {
  display: flex;
  align-items: center;
}

.projects__header-title {
  font-size: 24px;
}

.projects__header-refresh-button {
  width: auto;
  margin-left: 10px;
  padding-right: 15px;
  padding-left: 35px;
  border: 1px solid #d3d5db;
  border-radius: 8px;
  background-color: #fff;
  background-image: url(~@assets/imgs/undefined_imgs/refresh_icon.svg);
  background-position: 9.55% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.projects__header-refresh-button:hover {
  background-color: #dadada;
}
</style>
