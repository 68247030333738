<script>
  import MultiSelectFilter from '@/components/grid/filters/shared/MultiSelectFilter';

  export default {
    extends: MultiSelectFilter,
    computed: {
      placeholder() { return this.$gettext('Select status'); },
      selectList() {
        return [
          {id: '10', name: this.$gettext('Draft')},
          {id: '20', name: this.$gettext('Quote requested')},
          {id: '30', name: this.$gettext('Quote sent')},
          {id: '40', name: this.$gettext('Quote accepted')},
          {id: '50', name: this.$gettext('Quote rejected')},
          {id: '60', name: this.$gettext('In Progress')},
          {id: '70', name: this.$gettext('Finished')},
          {id: '80', name: this.$gettext('Cancelled')},
          {id: '90', name: this.$gettext('Completed')},
        ];
      },
    },
  };
</script>
