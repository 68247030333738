// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/projects_grid/filter_icon_inactive.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/projects_grid/filter_icon_active.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.project-id__cell-wrapper[data-v-531e599a] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  height: 100%;\n}\n.project-id__number[data-v-531e599a] {\n  margin-left: 5px;\n  font-weight: 600;\n}\n.godmode-link[data-v-531e599a] {\n  font: bold;\n}\n.godmode-link[data-v-531e599a]:not(:first-child) {\n  margin-left: 5px;\n}\n.project-id__filter[data-v-531e599a] {\n  width: 20px;\n  height: 20px;\n  margin: 0 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 100% auto;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n.project-id__filter[data-v-531e599a]:hover {\n  opacity: 0.65;\n}\n.project-id__filter--active[data-v-531e599a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
