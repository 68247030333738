<script>
  import CExternalDeadline from '@/components/grid/cells/project/CExternalDeadline';

  export default {
    extends: CExternalDeadline,
    computed: {
      deadLine() { return this.propData?.internalDeadline; },
      showBlock() { return !this.propData?.archived; },
    }
  };
</script>
