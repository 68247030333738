<script>
  import CHints from '@/components/grid/cells/hints/CHints';

  export default {
    extends: CHints,
    data() {
      return {
        wipAttachmentToolTipText: '',
        finalAttachmentToolTipText: '',
        invitationStatusToolTipText: ''
      };
    },
    computed: {
      showBlock() { return !this.params.data?.archived; },
      // ------- Attachments ------- //
      invitationStatusObj() { return this.params.data?.inviteStatus; },
      invitationStatusName() { return this.invitationStatusObj?.name; },
      invitedTranslatorName() { return this.invitationStatusObj?.translatorName; },
      invitationPerformedAt() { return this.invitationStatusObj?.actionPerformedAt; },
      // ------- Attachments ------- //
      wipAttachmentsPresent() { return this.params.data?.wipAttachmentsPresent; },
      finalAttachments() { return this.params.data?.finalAttachments; },
      // ------- Order Attachment ------- //
      initialAttachmentsPresent() { return this.params.data?.initialAttachmentsPresent; },
      showOcrAttachmentsToSeller() { return this.params.data?.showOcrAttachmentsToSeller; },
      // ------- Send By Post ------- //
      isPostDeliveryMethod() { return this.params.data?.deliveryMethod === 'post'; },
      sendByPostAddressStatus() { return this.params.data?.sendByPostAddressStatus; },
      sendByPostAddress() { return this.params.data?.sendByPostAddress; },
      sentByPost() { return this.params.data?.sentByPost; },
      // ------- Danger Level ------- //
      externalDeadline() { return this.params.data?.externalDeadline; },
      externalDeadlineF() { return this.externalDeadline ? this.$moment(this.externalDeadline).format('YYYY-MM-DD HH:mm:ss') : ''; },
      internalDeadline() { return this.params.data?.internalDeadline; },
      internalDeadlineF() { return this.internalDeadline ? this.$moment(this.internalDeadline).format('YYYY-MM-DD HH:mm:ss') : ''; },

      wipAttachmentStatus() {
        if (this.wipAttachmentsPresent) {
          this.setWipAttachmentToolTipText(this.$gettext('Work in progress files attached'));
          return 'wip-attachment--present';
        } else {
          this.setWipAttachmentToolTipText(this.$gettext(''));
          return 'wip-attachment--none';
        }
      },
      finalAttachmentStatus() {
        if (this.finalAttachments) {
          this.setFinalAttachmentToolTipText(this.$gettext('Final files attached'));
          return 'final-attachment--present';
        } else {
          this.setFinalAttachmentToolTipText(this.$gettext(''));
          return 'final-attachment--none';
        }
      },
      invitationStatus() {
        let temp = '';
        switch (this.invitationStatusName) {
          case 'disabled':
            this.setInvitationStatusToolTipText(this.$gettext('No translators have access. Please invite translators'));
            return 'subtask-invitation-status--disabled';
          case 'proactive_access':
            this.setInvitationStatusToolTipText(this.$gettext('All translators with relevant skills have access'));
            return 'subtask-invitation-status--public';
          case 'invited':
            temp = this.invitationPerformedAt ? this.$gettext('%{translator} <br> Was invited at: %{invitationPerformedAt}')
              : this.$gettext('%{translator} Was invited');
            this.setInvitationStatusToolTipText(this.$gettextInterpolate(temp, {translator: this.invitedTranslatorName, invitationPerformedAt: this.invitationPerformedAt}));
            return 'subtask-invitation-status--invited';
          case 'rejected':
            temp = this.$gettext('%{translator} rejected the order');
            this.setInvitationStatusToolTipText(this.$gettextInterpolate(temp, {translator: this.invitedTranslatorName}));
            return 'subtask-invitation-status--rejected';
          case 'accepted':
            temp = this.$gettext('%{translator} Accepted the order');
            this.setInvitationStatusToolTipText(this.$gettextInterpolate(temp, {translator: this.invitedTranslatorName}));
            return 'subtask-invitation-status--accepted';
          case 'finished':
            this.setInvitationStatusToolTipText(this.$gettext('Invitation finished'));
            return 'subtask-invitation-status--finished';
        }
      },
      dangerLevelStatus() {
        let temp = '';
        switch (this.dangerLevel) {
          case 2:
            temp = this.$gettext('Waiting for translators to accept the project <br>Deadline: %{deadline}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {deadline: this.internalDeadlineF}));
            return {level: 'B', class: 'application_hint'};
          case 3:
            temp = this.$gettext('No translators are invited or have access <br>Deadline: %{deadline}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {deadline: this.internalDeadlineF}));
            return {level: 'C', class: 'application_hint'};
          case 4:
            temp = this.$gettext('Invited translator has rejected the offer <br>Deadline: %{deadline}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {deadline: this.externalDeadlineF}));
            return {level: 'D', class: 'application_hint'};
          case 5:
            temp = this.$gettext('Internal deadline has passed <br>Deadline: %{deadline}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {deadline: this.internalDeadlineF}));
            return {level: 'E', class: 'application_hint'};
          case 6:
            temp = this.$gettext('External deadline is within 2 hours <br>Deadline: %{deadline}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {deadline: this.externalDeadlineF}));
            return {level: 'F', class: 'application_hint'};
          case 100:
            temp = this.$gettext('Deadline has past <br> %{deadline}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {deadline: this.externalDeadlineF}));
            return {level: '0', class: 'application-warning--orange'};
          case 1:
          default:
            temp = this.$gettext('The project is accepted by the translator <br> Deadline: %{deadline}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {deadline: this.internalDeadlineF}));
            return {level: 'A', class: 'application_hint'};
        }
      },
      hintIcons() {
        return [
          {
            id: 'subtask_invitation_status',
            classes: [this.invitationStatus],
            toolTipText: this.invitationStatusToolTipText,
            condition: true,
            onClick: () => {}
          },
          {
            id: 'application',
            classes: [this.dangerLevelStatus.class],
            toolTipText: this.applicationToolTipText,
            condition: true,
            onClick: () => {}
          },
          {
            id: 'subtask_wip_attachment',
            classes: [this.wipAttachmentStatus],
            toolTipText: this.wipAttachmentToolTipText,
            condition: true,
            onClick: () => {}
          },
          {
            id: 'subtask_final_attachment',
            classes: [this.finalAttachmentStatus],
            toolTipText: this.finalAttachmentToolTipText,
            condition: true,
            onClick: () => {}
          },
        ];
      }
    },
    methods: {
      setWipAttachmentToolTipText(value) {
        this.wipAttachmentToolTipText = value;
      },
      setFinalAttachmentToolTipText(value) {
        this.finalAttachmentToolTipText = value;
      },
      setInvitationStatusToolTipText(value) {
        this.invitationStatusToolTipText = value;
      },
    }
  };
</script>
