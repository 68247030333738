<script>
  import CPersonInfo from '@/components/grid/cells/shared/CPersonInfo';

  export default {
    extends: CPersonInfo,
    computed: {
      owner() { return this.propData?.owner; },
      ownerEmail() { return this.owner?.email; },
      ownerPhone() { return this.owner?.phone; },
      extraParticipantsCount() { return this.propData?.extraParticipantsCount; },
      enterprise() { return this.owner?.enterpriseName; },
      department() { return this.propData?.departmentName; },
      secondRow() { return this.enterprise ? this.enterprise + ', ' + this.department : '-'; },
      firstRow() { return this.owner?.name; }
    },
  };
</script>
