<script>
  import CLanguagePair from '@/components/grid/cells/job/CLanguagePair';

  export default {
    extends: CLanguagePair,
    computed: {
      languageToId() { return this.propData?.targetLanguageId; },
      languageFromId() { return this.propData?.sourceLanguageId; },
      alternativeLangToId() { return this.propData?.alternativeLanguageToId; },
      alternativeLanguageAllowed() { return this.propData?.alternativeLanguageAllowed; },
      showProcessingIcons() {
        return this.alternativeLanguageAllowed && this.alternativeLangToId;
      },
    }
  };
</script>
