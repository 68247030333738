(<template>
  <div class="project-id__cell-wrapper">
    <a class="sk-link sk-link--default project-id__number"
       target="_blank"
       :href="hamlAdminOrderLink">{{ publicId }}</a>
    <span class="project-id__filter"
          :class="{'project-id__filter--active': isOrderIdFilterActive}"
          @click="filterByOrderId()"></span>
    <div class="project-id__godmode-links">
      <button v-if="godmodeDemanderUrl"
              class="sk-link sk-link--default godmode-link"
              @click="openGodmodeLink(godmodeDemanderUrl)">{{ $gettext('[K]') }}</button>
      <button v-if="godmodeSupplierUrl"
              class="sk-link sk-link--default godmode-link"
              @click="openGodmodeLink(godmodeSupplierUrl)">{{ $gettext('[T]') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import constants from '@/modules/constants';

  export default {
    data() {
      return {
        filterById: false
      };
    },
    computed: {
      ...mapState('AllProjectsStore', {
        filterOrderId: (state) => state.filterOrderId || '',
      }),
      hamlAdminOrderLink() { return constants.HAML_ADMIN_PATH + `/translations/orders/${this.orderId}/edit`; },
      orderId() { return this.params.data?.orderId; },
      publicId() { return this.params.data?.publicId; },
      godmodeSupplierUrl() { return this.params.data?.godmodeSupplierUrl; },
      godmodeDemanderUrl() { return this.params.data?.godmodeDemanderUrl; },
      isOrderIdFilterActive() { return this.filterOrderId; }
    },
    methods: {
      openGodmodeLink(link) {
        window.open(link, '_blank');
      },
      filterByOrderId() {
        const newFilterId = this.isOrderIdFilterActive ? '' : this.orderId;
        this.$store.commit('AllProjectsStore/setFilterOrderId', newFilterId);
      }
    }
  };
</script>
<style scoped>
.project-id__cell-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.project-id__number {
  margin-left: 5px;
  font-weight: 600;
}

.godmode-link {
  font: bold;
}

.godmode-link:not(:first-child) {
  margin-left: 5px;
}

.project-id__filter {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  background-image: url(~@assets/imgs/projects_grid/filter_icon_inactive.svg);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  cursor: pointer;
}

.project-id__filter:hover {
  opacity: 0.65;
}

.project-id__filter--active {
  background-image: url(~@assets/imgs/projects_grid/filter_icon_active.svg);
}
</style>
