<script>
  import CViewers from '@/components/grid/cells/job/CViewers';

  export default {
    extends: CViewers,
    computed: {
      showBlock() { return !this.propData?.archived; },
      showViewers() { return false; }
    },
    methods: {
      changeSelectedManager(value) {
        if (value == 0) {
          this.assignedManagerUid = '';
        } else {
          this.assignedManagerUid = value;
        }
        const data = {
          payload: {
            manager_uid: this.assignedManagerUid === '' ? null : this.assignedManagerUid,
          },
          id: this.jobId
        };
        this.$store.dispatch('ManagerSocketDataStore/assignManagerToSingleSubtask', data);
      }
    },
    beforeMount() {
      this.assignedManagerUid = this.params.data?.assignedManager?.uid || '';
    },
  };
</script>
