// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/refresh_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.projects[data-v-8ff822d2] {\n  margin: 10px 20px;\n}\n.projects__cont[data-v-8ff822d2] {\n  padding: 10px 20px 20px 20px;\n  border-radius: 8px;\n  background-color: #fff;\n  -webkit-box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);\n          box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);\n}\n.project-tabs__cont[data-v-8ff822d2] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-bottom: 20px;\n  border-bottom: 1px solid #d3d5de;\n}\n.project-tabs__wrapper[data-v-8ff822d2] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.projects__header[data-v-8ff822d2] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  padding: 15px 0;\n}\n.projects__header--left[data-v-8ff822d2] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.projects__header-title[data-v-8ff822d2] {\n  font-size: 24px;\n}\n.projects__header-refresh-button[data-v-8ff822d2] {\n  width: auto;\n  margin-left: 10px;\n  padding-right: 15px;\n  padding-left: 35px;\n  border: 1px solid #d3d5db;\n  border-radius: 8px;\n  background-color: #fff;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 9.55% 50%;\n  background-size: 22px auto;\n  background-repeat: no-repeat;\n}\n.projects__header-refresh-button[data-v-8ff822d2]:hover {\n  background-color: #dadada;\n}\n", ""]);
// Exports
module.exports = exports;
