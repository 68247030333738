<script>
  import helpers from '@/helpers';
  import CStatusChip from '@/components/grid/cells/shared/CStatusChip';

  export default {
    extends: CStatusChip,
    computed: {
      status() {
        return helpers.getProjectInfo.subtaskStatusObjManagers(this, this.params.data?.archived ? 'archived' : this.params.data?.subtaskStatus);
      }
    },
  };
</script>
