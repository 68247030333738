<template>
  <div v-if="showBlock"
       class="word-count">
    <p>{{ wordCount }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      wordCount() { return this.propData?.wordcount; },
      showBlock() { return !this.propData?.archived; }
    }
  };
</script>
<style scoped>
.word-count {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
