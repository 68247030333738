<template>
  <section class="order-hints-filter">
    <hint-select v-for="hint in hintFilters"
                 :key="hint.key"
                 :items-list="hint.selectItems"
                 :class="[hint.classes]"
                 :hint-icon="hint.icon"
                 :preselected-value="hint.value"
                 @csvaluechanged="hint.onChange" />
  </section>
</template>

<script>
  import HintSelect from '@/components/grid/filters/hint/HintSelect';

  export default {
    components: {
      'hint-select': HintSelect
    },
    data() {
      return {
        accessLevel: '',
        initialAttachment: '',
        sendByPost: '',
        additionalCCEmail: ''
      };
    },
    computed: {
      hintFilters() {
        return [
          {
            key: Math.random(),
            classes: ['order-access-level'],
            selectItems: this.accessLevels,
            icon: 'order-access-level--none',
            value: this.accessLevel,
            onChange: this.onAccessLevelChanged
          },
          {
            key: Math.random(),
            classes: ['order-initial-attachment'],
            selectItems: this.initialAttachments,
            icon: 'order-attachment--none',
            value: this.initialAttachment,
            onChange: this.onInitialAttachmentChanged
          },
          {
            key: Math.random(),
            classes: ['order-send-by-post'],
            selectItems: this.sendByPostOptions,
            icon: 'send-by-post--none',
            value: this.sendByPost,
            onChange: this.onSendByPostChanged
          },
          {
            key: Math.random(),
            classes: ['order-additional-email'],
            selectItems: this.additionalCCEmailOptions,
            icon: 'send-to-cc-users--none',
            value: this.additionalCCEmail,
            onChange: this.onAdditionalCCEmailChanged
          }
        ];
      },
      initialAttachments() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'order-attachment--none'},
          {id: 'initialFilesAttached--true', name: this.$gettext('Files attached'), iconClass: 'order-attachment--present'},
          {id: 'initialFilesAttached--false', name: this.$gettext('Not attached'), iconClass: 'order-attachment--none'}
        ];
      },
      accessLevels() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'order-access-level--none'},
          {id: 'accessLevel--2', name: this.$gettext('Enterprise'), iconClass: 'order-access-level--enterprise'},
          {id: 'accessLevel--0', name: this.$gettext('Owner'), iconClass: 'order-access-level--owner'},
          {id: 'accessLevel--1', name: this.$gettext('Department'), iconClass: 'order-access-level--department'}
        ];
      },
      sendByPostOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'send-by-post--none'},
          {id: 'orderSendByPost--approved', name: this.$gettext('Approved'), iconClass: 'send-by-post--approved'},
          {id: 'orderSendByPost--sent', name: this.$gettext('Sent'), iconClass: 'send-by-post--sent'},
          {id: 'orderSendByPost--pending', name: this.$gettext('Pending'), iconClass: 'send-by-post--pending'},
          {id: 'orderSendByPost--rejected', name: this.$gettext('Rejected'), iconClass: 'send-by-post--rejected'},
          {id: 'orderSendByPost--emailDelivery', name: this.$gettext('Send by email'), iconClass: 'send-by-post--none'}
        ];
      },
      additionalCCEmailOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'send-to-cc-users--none'},
          {id: 'additionalCCEmail--gt', name: this.$gettext('Additional CC email present'), iconClass: 'send-to-cc-users--present'},
          {id: 'additionalCCEmail--eq', name: this.$gettext('No CC email'), iconClass: 'send-to-cc-users--none'}
        ];
      }
    },
    methods: {
      onInitialAttachmentChanged(value) {
        // reset filter value
        if (value == '-1') this.initialAttachment = '';
        else this.initialAttachment = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onAccessLevelChanged(value) {
        // reset filter value
        if (value == '-1') this.accessLevel = '';
        else this.accessLevel = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onSendByPostChanged(value) {
        // reset filter value
        if (value == '-1') this.sendByPost = '';
        else this.sendByPost = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onAdditionalCCEmailChanged(value) {
        // reset filter value
        if (value == '-1') this.additionalCCEmail = '';
        else this.additionalCCEmail = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.status = '';
        }
      }
    }
  };
</script>

<style>
.order-hints-filter .sk-select {
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.order-hints-filter {
  display: flex;
  width: 100%;
}

.order-hints-filter > *:not(:last-child) {
  margin-right: 2px;
}

.order-access-level .sk-select,
.order-access-level .sk-select.is-opened,
.order-initial-attachment .sk-select,
.order-initial-attachment .sk-select.is-opened,
.order-send-by-post .sk-select,
.order-send-by-post .sk-select.is-opened,
.order-additional-email .sk-select,
.order-additional-email .sk-select.is-opened {
  width: 22px;
  height: 0;
  border: none;
}
</style>
