<template>
  <section class="subtask-hints-filter">
    <hint-select v-for="hint in hintFilters"
                 :key="hint.key"
                 :items-list="hint.selectItems"
                 :class="[hint.classes]"
                 :hint-icon="hint.icon"
                 :type="hint.type"
                 :preselected-value="hint.value"
                 @csvaluechanged="hint.onChange" />
  </section>
</template>

<script>
  import HintSelect from '@/components/grid/filters/hint/HintSelect';

  export default {
    components: {
      'hint-select': HintSelect
    },
    data() {
      return {
        subtaskInvitationStatus: '',
        dangerLevel: '',
        wipAttachmentPresent: '',
        finalAttachmentPresent: '',
      };
    },
    computed: {
      hintFilters() {
        return [
          {
            key: Math.random(),
            classes: ['subtask-invitation-status'],
            type: '',
            selectItems: this.subtaskInvitationStatuses,
            icon: 'subtask-invitation-status--none',
            value: this.subtaskInvitationStatus,
            onChange: this.onSubtaskInvitationStatusChanged
          },
          {
            key: Math.random(),
            type: 'danger-level',
            classes: ['subtask-danger-level'],
            selectItems: this.dangerLevelOptions,
            icon: 'subtask-danger-level--none',
            value: this.dangerLevel,
            onChange: this.onDangerLevelChanged
          },
          {
            key: Math.random(),
            classes: ['wip-attachments'],
            type: '',
            selectItems: this.wipAttachmentOptions,
            icon: 'wip-attachment--none',
            value: this.wipAttachmentPresent,
            onChange: this.onWipAttachmentChanged
          },
          {
            key: Math.random(),
            classes: ['subtask-final-attachments'],
            type: '',
            selectItems: this.finalAttachmentOptions,
            icon: 'final-attachment--none',
            value: this.finalAttachmentPresent,
            onChange: this.onFinalAttachmentChanged
          }
        ];
      },
      dangerLevelOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'subtask-danger-level--none'},
          {id: 'subtaskDangerLevel--a',
           name: this.$gettext('Accepted'),
           iconClass: 'subtaskDangerLevel--a',
           dangerLevel: 'A',
           panicPercentage: 20},
          {id: 'subtaskDangerLevel--b',
           name: this.$gettext('Invited'),
           iconClass: 'subtaskDangerLevel--b',
           dangerLevel: 'B',
           panicPercentage: 25},
          {id: 'subtaskDangerLevel--c',
           name: this.$gettext('Not Invited'),
           iconClass: 'subtaskDangerLevel--c',
           dangerLevel: 'C',
           panicPercentage: 80},
          {id: 'subtaskDangerLevel--d',
           name: this.$gettext('Rejected'),
           iconClass: 'subtaskDangerLevel--d',
           dangerLevel: 'D',
           panicPercentage: 90},
          {id: 'subtaskDangerLevel--e',
           name: this.$gettext('Internal deadline passed'),
           iconClass: 'subtaskDangerLevel--e',
           dangerLevel: 'E',
           panicPercentage: 100},
          {id: 'subtaskDangerLevel--f',
           name: this.$gettext('External deadline within 2Hours'),
           iconClass: 'subtaskDangerLevel--f',
           dangerLevel: 'F',
           panicPercentage: 100},
          {id: 'subtaskDangerLevel--high',
           name: this.$gettext('Deadline has passed'),
           iconClass: 'subtask-danger-level--high',
           dangerLevel: '',
           panicPercentage: ''}
        ];
      },
      subtaskInvitationStatuses() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'subtask-invitation-status--none'},
          {id: 'subtaskInvitationStatus--20', name: this.$gettext('Public'), iconClass: 'subtask-invitation-status--public'},
          {id: 'subtaskInvitationStatus--30', name: this.$gettext('Invited'), iconClass: 'subtask-invitation-status--invited'},
          {id: 'subtaskInvitationStatus--50', name: this.$gettext('Accepted'), iconClass: 'subtask-invitation-status--accepted'},
          {id: 'subtaskInvitationStatus--60', name: this.$gettext('Rejected'), iconClass: 'subtask-invitation-status--rejected'},
          {id: 'subtaskInvitationStatus--80', name: this.$gettext('Finished'), iconClass: 'subtask-invitation-status--finished'},
          {id: 'subtaskInvitationStatus--disabled', name: this.$gettext('Disabled'), iconClass: 'subtask-invitation-status--disabled'}
        ];
      },
      wipAttachmentOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'wip-attachment--none'},
          {id: 'wipAttachmentPresent--true', name: this.$gettext('WIP attachment present'), iconClass: 'wip-attachment--present'},
          {id: 'wipAttachmentPresent--false', name: this.$gettext('No WIP attachment'), iconClass: 'wip-attachment--none'}
        ];
      },
      finalAttachmentOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'final-attachment--none'},
          {id: 'finalFilesAttached--true', name: this.$gettext('Final attachment present'), iconClass: 'final-attachment--present'},
          {id: 'finalFilesAttached--false', name: this.$gettext('No final attachment'), iconClass: 'final-attachment--none'}
        ];
      }
    },
    methods: {
      onDangerLevelChanged(value) {
        // reset filter value
        if (value == '-1') this.dangerLevel = '';
        else this.dangerLevel = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onSubtaskInvitationStatusChanged(value) {
        // reset filter value
        if (value == '-1') this.subtaskInvitationStatus = '';
        else this.subtaskInvitationStatus = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onFinalAttachmentChanged(value) {
        // reset filter value
        if (value == '-1') this.finalAttachmentPresent = '';
        else this.finalAttachmentPresent = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onWipAttachmentChanged(value) {
        // reset filter value
        if (value == '-1') this.wipAttachmentPresent = '';
        else this.wipAttachmentPresent = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.status = '';
        }
      }
    }
  };
</script>

<style>
.subtask-hints-filter .sk-select {
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.subtask-hints-filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.subtask-hints-filter > *:not(:last-child) {
  margin-right: 2px;
}

.subtask-invitation-status .sk-select,
.subtask-invitation-status .sk-select.is-opened,
.subtask-danger-level .sk-select,
.subtask-danger-level .sk-select.is-opened,
.subtask-final-attachments .sk-select,
.subtask-final-attachments .sk-select.is-opened,
.wip-attachments .sk-select,
.wip-attachments .sk-select.is-opened {
  width: 22px;
  height: 0;
  border: none;
}

.subtask-hints-filter .danger-level--selected-icon {
  margin-top: -6px;
}
</style>
