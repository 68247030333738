<script>
  import CHints from '@/components/grid/cells/hints/CHints';

  export default {
    extends: CHints,
    data() {
      return {
        accessLevelToolTipText: '',
        orderAttachmentToolTipText: '',
        sendByPostToolTipText: '',
        sendDocumentToCCEmails: '',
      };
    },
    computed: {
      accessLevel() { return this.params.data?.accessLevel; },
      // ------- Order Attachment ------- //
      initialAttachmentsPresent() { return this.params.data?.initialAttachmentsPresent; },
      showOcrAttachmentsToSeller() { return this.params.data?.showOcrAttachmentsToSeller; },
      // ------- Send By Post ------- //
      isPostDeliveryMethod() { return this.params.data?.deliveryMethod === 'post'; },
      sendByPostAddressStatus() { return this.params.data?.sendByPostAddressStatus; },
      sendByPostAddress() { return this.params.data?.sendByPostAddress; },
      sentByPost() { return this.params.data?.sentByPost; },

      // ------- Participants/ccEmails ------- //
      ccEmails() { return this.params.data?.ccEmails || []; },

      sendByPostStatus() {
        if (!this.isPostDeliveryMethod) {
          this.setSendByPostToolTipText(this.$gettext('No need to send documents by post'));
          return 'send-by-post--none';
        } else {
          if (this.sentByPost) {
            const temp = this.$gettext('The documents have been sent by post <br>Address: %{address}');
            this.setSendByPostToolTipText(this.$gettextInterpolate(temp, {address: this.sendByPostAddress || '-'}));
            this.setSendByPostToolTipText(this.$gettext('Document is attached'));
            return 'send-by-post--sent';
          } else if (this.sendByPostAddressStatus === 'approved') {
            this.setSendByPostToolTipText('Send documents by post');
            return 'send-by-post--approved';
          } else if (this.sendByPostAddressStatus === 'rejected') {
            this.setSendByPostToolTipText('Send documents by post - Information missing');
            return 'send-by-post--rejected';
          } else if (this.sendByPostAddressStatus === 'pending') {
            const temp = this.$gettext('Waiting for address check <br> Address: %{address}');
            this.setSendByPostToolTipText(this.$gettextInterpolate(temp, {address: this.sendByPostAddress || '-'}));
            return 'send-by-post--pending';
          }
        }
      },
      orderAttachmentStatus() {
        if (this.showOcrAttachmentsToSeller) {
          this.setOrderAttachmentToolTipText(this.$gettext('Show OCR attachment to translator'));
          return 'order-attachment--ocr';
        } else if (this.initialAttachmentsPresent) {
          this.setOrderAttachmentToolTipText(this.$gettext('Document is attached'));
          return 'order-attachment--present';
        } else {
          this.setOrderAttachmentToolTipText('No document was attached');
          return 'order-attachment--none';
        }
      },
      accessLevelStatus() {
        if (this.accessLevel === 'owner') {
          this.setAccessLevelToolTipText(this.$gettext('This project can only be viewed by you'));
          return 'order-access-level--owner';
        } else if (this.accessLevel === 'department') {
          this.setAccessLevelToolTipText(this.$gettext('This project is shared throughout your department'));
          return 'order-access-level--department';
        } else if (this.accessLevel === 'enterprise') {
          this.setAccessLevelToolTipText(this.$gettext('This project is shared throughout your enterprise'));
          return 'order-access-level--enterprise';
        } else {
          this.setAccessLevelToolTipText('');
          return 'order-access-level--none';
        }
      },
      sendDocumentToCCEmailsStatus() {
        if (this.ccEmails && this.ccEmails.length) {
          this.setSendDocumentCCEmailsToolTipText(this.$gettext('Send document to additional/cc users'));
          return 'send-to-cc-users--present';
        } else {
          this.setSendDocumentCCEmailsToolTipText('No additional users to send documents to');
          return 'send-to-cc-users--none';
        }
      },
      hintIcons() {
        return [
          {
            id: 'order_access_level',
            classes: [this.accessLevelStatus],
            toolTipText: this.accessLevelToolTipText,
            condition: true,
            onClick: () => {}
          },
          {
            id: 'order_attachment',
            classes: [this.orderAttachmentStatus],
            toolTipText: this.orderAttachmentToolTipText,
            condition: true,
            onClick: () => {}
          },
          {
            id: 'send_by_post',
            classes: [this.sendByPostStatus],
            toolTipText: this.sendByPostToolTipText,
            condition: true,
            onClick: () => {}
          }, {
            id: 'send_document_to_cc_emails',
            classes: [this.sendDocumentToCCEmailsStatus],
            toolTipText: this.sendDocumentToCCEmails,
            condition: true,
            onClick: () => {}
          },
        ];
      }
    },
    methods: {
      setAccessLevelToolTipText(value) {
        this.accessLevelToolTipText = value;
      },
      setOrderAttachmentToolTipText(value) {
        this.orderAttachmentToolTipText = value;
      },
      setSendByPostToolTipText(value) {
        this.sendByPostToolTipText = value;
      },
      setSendDocumentCCEmailsToolTipText(value) {
        this.sendDocumentToCCEmails = value;
      },
    }
  };
</script>
